import React from 'react'

const Botonera = ({handleUpload}) => {
  return (
    <div
    className="divbotonesbajocamara"
    style={{
      width: "60%",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    }}
  >

    <div
      className="divbotonesbajocamara"
      style={{
        display: "flex",
        width: "80%",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
 
          <button
            className="btn btn-primary botonsubirfoto"
            onClick={(event) => handleUpload(event, "inasis")}
            title="Subir foto"
            style={{
              width: "50%",
              height: "50px",
              borderRadius: "2px",
            }}
          >
            REGISTRA FALTO <i className="fa-solid fa-floppy-disk"></i>
          </button>
          <button
            className="btn btn-warning botonsubirfoto"
            onClick={(event) => handleUpload(event, "desc")}
            title="Subir foto"
            style={{
              width: "50%",
              height: "50px",
              borderRadius: "2px",
            }}
          >
            REGISTRA DESCANSO <i className="fa-solid fa-floppy-disk"></i>
          </button>
          <button
            className="btn btn-danger botonsubirfoto"
            onClick={(event) => handleUpload(event, "compen")}
            title="Subir foto"
            style={{
              width: "50%",
              height: "50px",
              borderRadius: "2px",
            }}
          >
            REGISTRA COMPENSACIÓN <i className="fa-solid fa-floppy-disk"></i>
          </button>
    </div>
  </div>
  )
}

export default Botonera