import React from 'react'
import Select from "react-select";

const DatosxUsuario = ({nombres,apellidopat,apellidomat,documento,selectedFuncion,funciones,setSelectedFuncion}) => {
  return (
    <>
    <div
      className="mb-3 divselectpersona"
      style={{
        width: "40%",
        padding: "15px",
      }}
    >
      <label className="form-label">Datos:</label>
      <div className='divdatosdniapellidos' style={{ display: "flex", gap: "10px", width: "100%" }}>
        <input
          type="text"
          value={`${nombres} ${apellidopat ? apellidopat : ''} ${apellidomat ? apellidomat : ''} `}
          id="placaInput"
          className="form-control"
          style={{
            border: "1px solid rgb(53, 53, 166)",
            textAlign: "center",
            width: "50%",
            fontSize: "15px",
            borderRadius: "2px",
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            resize: "none",
            height: "auto",
            padding: "5px"
          }}
          disabled
        />
        <input
          type="text"
          value={documento}
          className="form-control"
          id="placaInput2"
          style={{
            border: "1px solid rgb(53, 53, 166)",
            textAlign: "center",
            width: "50%",
            fontSize: "15px",
            borderRadius: "2px",
          }}
          disabled
        />
      </div>
    </div>

      <div
        className="mb-3 divselectfuncion"
        style={{
          width: "80%",
          padding: "15px",
          paddingTop: "0px",
          display:'flex',
          justifyContent:'center'
        }}
      >
        <input
          type="text"
          value={`${selectedFuncion}`}
          id="placaInput"
          className="form-control"
          style={{
            border: "1px solid rgb(53, 53, 166)",
            textAlign: "center",
            width: "40%",
            fontSize: "15px",
            borderRadius: "2px",
          }}
          disabled
        />
      </div>
  </>
  )
}

export default DatosxUsuario